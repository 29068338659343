import { GetServerSideProps, GetServerSidePropsContext, GetServerSidePropsResult, NextPage } from 'next';
import Head from 'next/head';
import { AxiosError, AxiosResponse } from 'axios';

import { useDidomiNoticeStore } from '@/store/useDidomiNoticeStore';
import { DataItem, getMainPosts, getPostsByLikes } from '@/api/posts';
import { ICategoryData } from '@/api/categories/interfaces';
import { ITag } from '@/api/tags/interfaces';
import { getSingleTag } from '@/api/tags/tags';
import { getCategories } from '@/api/categories/categories';
import { sortCategories } from '@/helpers/sortCategories';
import { PageFeedTemplate } from '@/components/templates/PageFeedTemplate';
import { useAdPageName } from '@/hooks/useAdPageName';
import { AdsSetup } from '@/components/organisms/AdCompanion';
import { getRemoteConfigValue } from '@/firebase/remoteConfig';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const appInsights = require('@azure');

const DECATHLON_TOKEN = process.env.DECATHLON_TOKEN as string;

interface HomePageProps {
  posts: DataItem[];
  favouritePosts: DataItem[];
  categoriesData: ICategoryData[];
  pageCount: number;
  singleTag: ITag | null;
  error?: any;
}

export const getServerSideProps: GetServerSideProps<HomePageProps> = async (
  context: GetServerSidePropsContext,
): Promise<GetServerSidePropsResult<HomePageProps>> => {
  context.res.setHeader('Cache-Control', 'max-age=0');

  try {
    const { query } = context;
    const { page, tag, tagId, top } = query;
    const pageNumber = page || 1;
    const [mainResponse, favouriteResponse, singleTagResponse, categoriesResponse] = await Promise.all([
      getMainPosts(pageNumber as number, tag as string, top as string),
      getPostsByLikes('24h'),
      getSingleTag(tagId as string),
      getCategories(),
    ]);

    const mainData = mainResponse.data;
    const favouritePosts = favouriteResponse.data.data;
    const { pageCount } = mainData.meta.pagination;
    const singleTag = singleTagResponse?.data ?? null;
    const categoriesData = sortCategories(categoriesResponse.data);

    return {
      props: {
        posts: mainData.data,
        favouritePosts,
        pageCount,
        singleTag,
        categoriesData,
      },
    };
  } catch (error) {
    const { response } = error as AxiosError;
    const { status } = response as AxiosResponse;

    if (status !== 404) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      appInsights.defaultClient.trackException({ exception: error });
    }

    return {
      props: {
        posts: [],
        favouritePosts: [],
        pageCount: 0,
        singleTag: null,
        categoriesData: [],
      },
    };
  }
};

const HomePage: NextPage<HomePageProps> = (props) => {
  const { getAdPageName } = useAdPageName();
  const pageName = getAdPageName();
  const isNoticeReady = useDidomiNoticeStore((state) => state.isNoticeReady);
  const isProductionENV = process.env.ENV_TYPE === 'prod';

  return (
    <>
      {isNoticeReady && <AdsSetup getRemoteConfigValue={getRemoteConfigValue} pageId={1878515} pageName={pageName} />}
      <Head>
        <title>Fangol.pl - Piłka w skrócie</title>
        <meta
          name="description"
          content="Fangol.pl - piłka w skrócie. Newsy, memy i relacje z meczów. Przeglądaj codziennie aktualności ze świata futbolu. Oceniaj i komentuj wrzuty, głosuj w ankietach, rozwiązuj nieprzeciętne piłkarskie quizy."
        />
        <meta name="convertiser-verification" content={DECATHLON_TOKEN} />
        {!isProductionENV && <meta name="robots" content="noindex" />}
      </Head>
      <PageFeedTemplate {...props} showFilterPanel />
    </>
  );
};

export default HomePage;
